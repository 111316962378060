<template>
   <section class="cont fission-box">
      <!-- 面包屑 -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>数据中台</el-breadcrumb-item>
            <el-breadcrumb-item>SCRM</el-breadcrumb-item>
            <el-breadcrumb-item>会员裂变</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <el-row class="content-box">
         <el-tabs v-model="tabState" @tab-click="tabClick" class="tab-box">
            <el-tab-pane label="裂变明细" name="detailed"></el-tab-pane>
            <el-tab-pane label="裂变设置" name="set"></el-tab-pane>
         </el-tabs>
         <!-- 查询框  -->
         <template v-if="tabState === 'detailed'">
            <el-row class="search-box">
               <el-row class="search-row">
                  <el-row class="search-item">
                     <label>手机号：</label>
                     <el-input class="width-220" placeholder="请输入手机号" v-model="mobile" clearable :maxlength="11"></el-input>
                  </el-row>
                  <el-row class="search-item">
                     <label>ID号：</label>
                     <el-input class="width-220" placeholder="请输入ID号" v-model="memberId" clearable></el-input>
                  </el-row>
                  <el-row class="search-item">
                     <label>会员等级：</label>
                     <el-select class="width-220" v-model="level" placeholder="请选择" clearable>
                        <el-option
                              v-for="item in levelList"
                              :key="item.medalCode"
                              :label="item.medalName"
                              :value="item.medalCode">
                        </el-option>
                     </el-select>
                  </el-row>
                  <el-row class="search-item">
                     <label>昵称：</label>
                     <el-input class="width-220" placeholder="请输入昵称" v-model="nickName" clearable></el-input>
                  </el-row>
               </el-row>
               <el-row class="search-row">
                  <el-button class="bg-gradient" @click="queryEvent">搜索</el-button>
                  <el-button class="bg-gradient" @click="resetEvent(true)">重置</el-button>
               </el-row>
            </el-row>
         </template>
         <el-alert class="m-bottom-20" title="裂变：指用户分享给没有在平台内有订房记录的用户，进行首次订房即为成功裂变。达到拉新人数可忽略积分要求进行升级。"
                   show-icon type="warning" :closable="false" v-if="tabState === 'set'"></el-alert>
            <!-- 主体内容 -->
         <el-row class="table-box">
               <el-table
                     v-loading="loading"
                     :data="tableData"
                     border fit
                     style="width: 100%"
                     :stripe="true"
                     :header-cell-style="{ background: '#f5f8f9'}">
                  <template v-if="tabState === 'detailed'">
                     <el-table-column label="序号" type="index" width="55" key="index"></el-table-column>
                     <el-table-column label="裂变人员信息" min-width="80" key="memberId">
                        <template slot-scope="scope">
                           <el-image class="img-box" :src="scope.row.headPortrait"></el-image>
                           <ul style="float: left;list-style: none;width:calc(100% - 75px);">
                              <li style="white-space: nowrap;overflow: hidden;text-overflow:ellipsis;">ID：{{ scope.row.memberId }}</li>
                              <li style="white-space: nowrap;overflow: hidden;text-overflow:ellipsis;">昵称：{{ scope.row.nickName }}</li>
                              <li style="white-space: nowrap;overflow: hidden;text-overflow:ellipsis;">手机号：{{ scope.row.mobile }}</li>
                           </ul>
                        </template>
                     </el-table-column>
                     <el-table-column label="推荐人" prop="parentName" key="parentName"></el-table-column>
                     <el-table-column label="裂变人数" prop="childCount" key="childCount"></el-table-column>
                     <el-table-column label="等级" key="level">
                        <template slot-scope="scope">
                           <span>{{ scope.row.level | filterLevel(that) }}</span>
                        </template>
                     </el-table-column>
                  </template>
                  <template v-if="tabState === 'set'">
                     <el-table-column label="会员名称" prop="medalName" key="medalName"></el-table-column>
                     <el-table-column label="升级所需裂变人数" prop="memberNum" key="memberNum">
                        <template slot-scope="scope">
                           <span :class="scope.row.bool ? 'errMember' : ''">
                              {{ scope.row.memberNum === 0 ? '0' : scope.row.memberNum ? scope.row.memberNum  : '-' }}
                           </span>
                        </template>
                     </el-table-column>
                     <el-table-column label="额外奖励">
                        <template slot-scope="scope">
                           <span>{{ scope.row.awardData }}</span>
                        </template>
                     </el-table-column>
                     <el-table-column label="操作时间" prop="createTime" key="createTime"></el-table-column>
                  </template>
                  <el-table-column label="操作" fixed="right">
                     <template slot-scope="scope">
                        <el-button type="text"><el-link type="primary" @click="handleCheck(scope.row,scope.$index)">{{tabState === 'detailed' ? '查看明细' : '编辑'}}</el-link></el-button>
                     </template>
                  </el-table-column>
               </el-table>
               <!-- 分页  -->
               <pagination :total="total" :currentPage="page" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging" v-if="tabState === 'detailed'"/>
            </el-row>
      </el-row>
      <!-- 编辑弹窗 -->
      <el-dialog title="规则编辑" :visible.sync="isShow" width="550px" v-if="isShow">
         <el-form class="form-dialog-box" :model="ruleForm" :rules="rules" ref="ruleForm">
            <el-form-item label="裂变人数：">
               <el-input  class="width-300" v-model="ruleForm.memberNum" placeholder="请输入裂变人数" clearable></el-input>
            </el-form-item>
            <el-form-item label="额外奖励" prop="awardType">
               <el-radio-group v-model="ruleForm.awardType" @change="handleRadio">
                  <el-radio label="NULL">无</el-radio>
                  <el-radio label="COUPON">优惠券</el-radio>
                  <el-radio label="DEPOSITCARD">储值卡</el-radio>
               </el-radio-group>
            </el-form-item>
            <div v-if="ruleForm.awardType === 'COUPON'">
               <el-form-item label="选择赠品">
                  <el-button class="bg-gradient" @click="handleAdd">添加</el-button>
               </el-form-item>
               <el-table
                     :data="rule_tableData"
                     border fit
                     style="width: 100%"
                     :stripe="true"
                     :header-cell-style="{ background: '#f5f8f9' }">
                  <el-table-column label="优惠券名称" prop="giftName"></el-table-column>
                  <el-table-column label="数量" width="120" prop="giftNum"></el-table-column>
               </el-table>
            </div>
            <el-form-item label="金额" prop="giftAmount" v-if="ruleForm.awardType === 'DEPOSITCARD'">
               <el-input class="width-300" v-model="ruleForm.giftAmount" placeholder="请输入金额" clearable> </el-input>
            </el-form-item>
         </el-form>
         <div slot="footer" class="dialog-footer">
            <el-button @click="isShow = false">取消</el-button>
            <el-button class="bg-gradient" type="primary" @click="submitForm('ruleForm',)">确定</el-button>
         </div>
      </el-dialog>
      <!-- 查看明细/选择优惠券  -->
      <el-dialog :title="selectTitle" :visible.sync="selectShow" width="550px"  v-if="selectShow">
         <el-table
               ref="multipleTable"
               v-loading="select_loading"
               :data="select_tableData"
               border fit
               style="width: 100%"
               :stripe="true"
               @selection-change="handleSelectionChange"
               :header-cell-style="{background: '#f5f8f9'}">
            <template v-if="tabState === 'detailed'">
               <el-table-column label="用户ID" key="memberId" prop="memberId"></el-table-column>
               <el-table-column label="手机号" key="mobile" prop="mobile"></el-table-column>
               <el-table-column label="裂变时间" key="createTime" prop="createTime">
                  <template slot-scope="scope">
                     <span>{{ scope.row.createTime | filterCreateTime }}</span>
                  </template>
               </el-table-column>
            </template>
            <template v-if="tabState === 'set'">
               <el-table-column label="选择" type="selection" key="selection"></el-table-column>
               <el-table-column label="优惠券名称" prop="mainTitle" key="mainTitle">
               </el-table-column>
               <el-table-column label="数量" key="giftNum">
                  <template slot-scope="scope">
                     <input v-model="scope.row.giftNum" class="modInput"
                            placeholder="请输入" oninput="value=value.replace(/\D|^0/g,'')"
                             clearable/>
<!--  @change="resteInventaire" -->
                  </template>
               </el-table-column>
            </template>
         </el-table>
         <div slot="footer" class="dialog-footer">
            <el-button @click=" selectShow = false " :class="tabState === 'detailed' ? 'bg-gradient' : '' ">{{tabState === 'detailed' ? '关闭' : '取消' }}</el-button>
            <el-button class="bg-gradient" type="primary" @click="select_define" v-if="tabState === 'set'">确定</el-button>
         </div>
      </el-dialog>
   </section>
</template>

<script>
import { urlObj , business } from '@/api/interface' //, coupon
import { coupon } from '@/api/interface/business'
import { verificationRule } from '@/common/js/common'
import { mapState } from "vuex";
export default {
   name: "fission",
   data(){
/*      const validNumber = (rule, value, callback) => {
         if (value === '') {
            callback(new Error('请输入裂变人数'))
         }else if (verificationRule.integer(value)){
            callback(new Error('请输入正整数'))
         } else {
            callback()
         }
      }*/
      return{
         that:this,
         tabState:'detailed', //tab状态
         mobile:'',        //手机号
         memberId:'',      //id号
         level:'',         //会员等级
         levelList:[],     //会员等级下拉数据
         filterlevel:[],   //
         nickName:'',      //昵称
         loading:true,
         tableData:[],
         ruleForm:{
            memberNum:'',        //裂变人数
            awardType:'',        //额外奖励
            giftAmount:'',        //金额
         },
         rules:{
            // memberNum:[{ required: true, validator : validNumber, trigger: 'blur'}],
            giftAmount:[{ required: true, message: '请输入金额', trigger: 'blur'}],
         },

         /* 规则编辑 */
         isShow:false,
         rule_tableData:[],
         /* 查看明细/规则编辑 */
         selectTitle:'',
         selectShow:false,
         select_loading:true,
         select_tableData:[],
         multipleSelection:[],  //选中值

         total: 0,             // 用户列表总条目数
         page: 1,              // 当前页 默认第一页
         limit: 0,             // 每页显示数
         rowEdit:"",           // 编辑传递数据
         rowEditIndex:'',      // 编辑下标

      }
   },
   watch: {
      hotelInfo: {
         handler(newVal, oldVal) {
            if (oldVal && newVal.id !== oldVal.id) {
               if(this.tabState === "detailed") this.getLevel('option')
               this.getList()
            }
         },
         deep: true
      }
   },
   computed: {
      ...mapState(['hotelInfo','dictData'])
   },
   // giftNum
   mounted() {
      this.limit = sessionStorage.getItem('pageSize') * 1
      this.getLevel('option')
      this.getList()
   },
   methods:{
      //获取会员等级
      getLevel(state){
         const url = urlObj.memberLevels + `?page=${this.page}&limit=999`
         const param = { hotelId: this.hotelInfo.id }
         if (JSON.parse(sessionStorage.getItem('hotelInfo')).accountType === 'HOTEL') {
            param.hotelId = this.hotelInfo.hotelId
         }else {
            param.companyId = this.hotelInfo.storeId
           delete param.hotelId
         }
         this.$axios.post( url, param ).then(res => {
            if (res.success) {
               let data = ''
               //  获取会员等级 下拉数据
               if(state === 'option'){
                  this.levelList = [] //下拉数据清空
                  this.filterlevel = {}; //等级过滤数据处理
                  data = res.records
                  data.forEach(item=>{
                     this.levelList.push({
                        medalCode:item.medalCode,
                        medalName:item.medalName
                     })
                     // 等级过滤回显数据处理
                     this.filterlevel[item.medalCode] = item.medalName;
                  })
               //   获取裂变设置
               }else if(state === 'tabSet'){
                  let bool = false
                  data = res.records
                  if (data.length !== 0){
                     let contrastNum = data[0].memberNum - 1
                     data.forEach((item,index) =>{
                        if(!item.hasOwnProperty('awardType')) item.awardType = 'NULL'
                        if(item.awardType === 'NULL'){
                           item.awardData = '无'
                        }else if(item.awardType === "COUPON"){
                           let arr = []
                           item.memberFissionGiftList.forEach(item=>{
                              arr.push( item.giftName + '*' + item.giftNum )
                           })
                           item.awardData = arr.join('；')
                        }else{
                           item.awardData = '储值卡+' + item.giftAmount
                        }
                        // 升级所需裂变人数 下值小于上值时标红提示文本 正常须为依次从小到大
                        if( contrastNum >= item.memberNum ){
                           item.bool = true
                           bool = true
                        }
                        contrastNum = item.memberNum
                     })
                  }
                  this.tableData = data
                  this.loading = false
                  if(bool) this.empty()
               }
            }
         })
      },
      //获取表格数据
      getList(row,state){
         if(this.tabState === "detailed"){
            const url = urlObj.getMenFission + `?limit=${this.limit}&page=${this.page}`
            let param = {
               mobile:this.mobile,
               memberId:this.memberId,
               level:this.level,
               nickName:this.nickName,
            }
            if (JSON.parse(sessionStorage.getItem('hotelInfo')).accountType === 'HOTEL') {
               param.hotelId = this.hotelInfo.hotelId
            }else {
               param.companyId = this.hotelInfo.storeId
            }
            if(state === 'checkDetailed') {
               param.parentId = row.memberId
               delete param.memberId
            }
             this.$axios.post(url,param,'json').then( res => {
               if (res.success) {
                  if(state !== 'checkDetailed'){
                     this.tableData = res.records
                     this.loading = false
                     this.total = res.total
                  }else{
                     this.select_tableData = res.records
                     this.select_loading = false
                  }
               }
            })
         }else{
            this.getLevel('tabSet')
         }
      },
      //tab切换
      tabClick(tab){
         if(tab.index === '0'){
            this.tabState = 'detailed'
         }else{
            this.tabState = 'set'
            this.resetEvent(false)
         }
         this.loading = true
         this.tableData = []
         this.getList()
      },
      // 搜索
      queryEvent(){
         this.getList()
      },
      // 重置
      resetEvent(bool){
         this.mobile = ''        //手机号
         this.memberId = ''      //id号
         this.level = ''         //会员等级
         this.nickName = ''      //昵称
         if(bool) this.getList()
      },
      //查看明细/编辑
      handleCheck(row,index){
         //查看明细
         if(this.tabState === 'detailed'){
            this.selectTitle  = '查看明细'
            this.selectShow = true
            this.select_tableData = []
            this.select_loading = true
            this.getList(row,'checkDetailed')
         }else{
            this.rowEdit = row // 点击编辑当前行数据
            this.rowEditIndex = index
            this.isShow = true
            if(!row.hasOwnProperty('awardType'))  row.awardType = ''
            this.ruleForm.awardType = row.awardType
            this.ruleForm.memberNum = row.memberNum
            this.awardType(row)
         }
      },
      // 额外奖金回显
      awardType(row){
         if(row.awardType === 'NULL'){
            this.ruleForm.giftAmoun = ''
            this.rule_tableData = []
         }else if(row.awardType === 'COUPON'){
            this.ruleForm.giftAmount = ''
            this.rule_tableData = row.memberFissionGiftList
         }else if(row.awardType === 'DEPOSITCARD'){
            this.rule_tableData = []
            this.ruleForm.giftAmount = row.giftAmount
         }
      },
      //单选框 绑定值变化时触发
      handleRadio(){
         this.awardType(this.rowEdit)
      },
      // 规则编辑 确定键
      submitForm(formName){
         this.$refs[formName].validate((valid) => {
            if (valid) {
               this.setEdit()
               // let bool = false
               // let previous = null
               // let next = null
               // //无上一个值
               // if(!this.tableData[this.rowEditIndex-1]){
               //    previous = 0
               // }else{
               //    previous = this.tableData[this.rowEditIndex-1].memberNum
               // }
               // //无下一个值
               // if(!this.tableData[this.rowEditIndex+1]) {
               //    next = Number.POSITIVE_INFINITY
               // }else{
               //    next = this.tableData[this.rowEditIndex+1].memberNum
               // }
               // if( previous < Number(param.memberNum) && Number(param.memberNum) < next ){
               //    bool = false
               // }else{
               //    bool = true
               // }

               // if(bool){
               //    if (next === Number.POSITIVE_INFINITY){
               //       message = `裂变人数须在${previous}以上`
               //    }else {
               //       message = `裂变人数须在${previous}-${next}区间`
               //    }
               // }else{
                  // this.$axios.post(url,param,'json').then(res => { })
               // }
            } else {
               return false;
            }
         });
      },


      setEdit(){
         const url = urlObj.MenFissionEdit
         let param = {
            memberNum: this.ruleForm.memberNum, // 裂变人数
            awardType: this.ruleForm.awardType,
            id:this.rowEdit.id,
         }
         let reState = null
         //储值卡
         if(this.ruleForm.awardType === 'DEPOSITCARD'){
            delete param.memberFissionGiftList
            param.giftAmount = this.ruleForm.giftAmount
         }
         //优惠券
         if(this.ruleForm.awardType === 'COUPON'){
            delete param.giftAmount
            this.memberFissionGiftList = []
            this.rule_tableData.forEach(item=>{
               this.memberFissionGiftList.push({
                  medalId:this.rowEdit.id,
                  giftId:item.id,
                  giftName:item.giftName,
                  giftNum:item.giftNum
               })
            })
            param.memberFissionGiftList = this.memberFissionGiftList
         }
         // 不然输入重复的裂变人数值
         this.tableData.forEach((item,index) => {
            if(this.rowEditIndex !== index){
               if(item.memberNum === Number(param.memberNum)) reState = 'repeat'
            }
         })
         let previousData = null  // 上值
         let nextData = null      // 下值
         // 上值处理
         if(!this.tableData[this.rowEditIndex-1]) {
            previousData = 0
         }else{
            if(this.tableData[this.rowEditIndex-1].memberNum){ //上一行裂变人数
               previousData = this.tableData[this.rowEditIndex-1].memberNum
            }else{ // 如果上一行数据为undefined，过滤出以上全部值，获取不为undefined的上限值，如果没有则取0
               let arr = []
               this.tableData.forEach((item,index)=>{
                  if(this.rowEditIndex > index) arr.push(item.memberNum)//过滤出以上全部值
               })
               // 找最终上限值，如果没有上限值，则取0
               let previous = arr.reverse().find((item)=>{ return item })
               if(previous){
                  previousData = previous
               }else{
                  previousData = 0
               }
            }
         }
         // 下值处理
         if(!this.tableData[this.rowEditIndex+1]) {
            nextData = Number.POSITIVE_INFINITY
         }else{
            if(this.tableData[this.rowEditIndex+1].memberNum){ //下一行裂变人数
               nextData = this.tableData[this.rowEditIndex+1].memberNum
            }else{ // 如果下一行数据为undefined，过滤出以下全部值
               let arr = []
               this.tableData.forEach((item,index)=>{
                  if(this.rowEditIndex < index) arr.push(item.memberNum)//过滤出以下全部值
               })
               // 找最终下限值，如果没有上限值，则取正无穷
               let next =  arr.find((item)=>{ return item })
               if(next){
                  nextData = next
               }else{
                  nextData = Number.POSITIVE_INFINITY
               }
            }
         }
         let bool = false
         if( previousData < Number(param.memberNum) && Number(param.memberNum) < nextData){
            bool = false
         }else{
            bool = true
         }
         if(reState === 'repeat' ) {
            this.$message({
               type: 'error',
               message: '该裂变人数已重复，请输入其他值'
            })
            }else if(bool){
               let message = ''
               if (nextData === Number.POSITIVE_INFINITY){
                  message = `裂变人数须在${previousData}以上`
               }else{
                  message = `裂变人数须在${previousData}-${nextData}区间`
               }
               this.$message({
                  type:'error',
                  message:message
               })
            }else{
            this.$axios.post(url,param,'json').then(res => {
               if (res.success) {
                  this.$message({
                     message:'编辑成功',
                     type:'success'
                  })
                  this.isShow = false
                  this.getLevel('tabSet')
               }
            })
         }
      },
      // 清空
      empty(){
         let arrId = []
         this.tableData.forEach((item,index) => {
            arrId.push(item.id)
         })
         let url = urlObj.resetMedal
         let param = { rightsIdList:arrId.join(',') }
         this.$axios.post(url,param).then(res => {
            if(res.success){
               this.getLevel('tabSet')
            }
         })
      },

      //添加 选择优惠券
      handleAdd(){
         this.selectTitle  = '选择优惠券'
         this.selectShow = true
         const url = coupon.couponPage + `?limit=999&page=${this.page}`
         const param = {
            hotelId: this.hotelInfo.id,
            status: "PROCESSING",
            thresholdType:'GIFT',
         }
         this.$axios.post(url, param, 'json').then(res => {
            if (res.success) {
               this.select_loading = false
               this.select_tableData =  res.records
               this.select_tableData = this.select_tableData.filter(item=>{
                 return item.limitQuantity != 0
               })
               // 多选数据回显
               if(this.rule_tableData.length > 0 ){
                  this.select_tableData.forEach((item)=>{
                     item.resteInventaire = item.issueCount - item.couponCollection // 剩余库存 = 发行量 - 领券量
                     this.rule_tableData.forEach(row=>{
                        item.giftNum = null
                        if(!row.hasOwnProperty('medalId')) row.giftId = row.id
                        if(item.id === row.giftId){
                           this.$nextTick(() => {
                              item.giftNum = row.giftNum
                              this.$refs.multipleTable.toggleRowSelection(item,true)
                           });
                        }
                     })
                  })
               }
            }
         })
      },
      // 选择优惠券 选中值
      handleSelectionChange(val){
         this.multipleSelection = val;
      },
      // resteInventaire(){
      //    // 库存不足提示
      //    this.multipleSelection.forEach(item=>{
      //       if(item.giftNum) {
      //          if(Number(item.giftNum) > item.resteInventaire){
      //             this.$message({
      //                type:'error',
      //                message:`${item.mainTitle}库存不足，仅剩${item.resteInventaire}`
      //             })
      //          }
      //       }
      //    })
      // },
      //选择优惠券 确定键
      select_define(){
         let arr = []
         let state = '' // 如果选中优惠券选项没有 数量值 进行提示
         this.multipleSelection.forEach(item=>{
            if(!item.giftNum) state = 'noGiftNum'
            arr.push({
               giftName:item.mainTitle,
               giftNum:item.giftNum,
               id:item.id
            })
         })
         let rowItem = ''
         this.multipleSelection.forEach( item => {
            if(item.giftNum) {
               if(Number(item.giftNum) > item.resteInventaire){
                  rowItem = item
                  state = 'resteInventaire'
               }
            }
         })
         if(!state){
            this.rule_tableData = arr
            this.selectShow = false
         }else{
            let messageTitle = ''
            if(state === 'noGiftNum'){
               messageTitle = '请输入优惠券数量'
            }else{
               messageTitle =`${ rowItem.mainTitle }库存不足，仅剩${ rowItem.resteInventaire }`
            }
            this.$message({
               type:'error',
               message:messageTitle
            })
         }
      },
      // 改变每页数
      pageChange(num) {
         this.limit = num
         this.getList()
      },
      // 改变当前页
      handlePaging(num) {
         this.page = num
         this.getList()
      },
   },
   filters:{
      filterLevel(val,that){
         if(val === 'user') return '普通会员' // 此值为初始默认等级
         if (val && that.filterlevel) return that.filterlevel[val] || val
      },
      filterCreateTime(val){
         return val.replace('T',' ')
      }
   }

}
</script>

<style scoped lang="scss">
.fission-box{
   .tab-box{
      //width: 186px;
      ::v-deep .el-tabs__header {
         margin: 0 0 31px;
         border-bottom: 1px solid #E3E4E5;
         .el-tabs__item{
            font-size: 18px;
            //font-family: Microsoft YaHei;
            font-weight: 400;
            color: #2D2D2D;
         }
         .is-active{
            font-size: 18px;
            font-weight: bold;
            color: #2577E3;
         }
      }
   }
   .img-box{
      width: 60px;height: 60px;
      border-radius: 30px;
      float: left;margin-right: 15px;
   }
   .errMember{
      color: red;
   }
}
.modInput{
   border: 1px solid #dedede;
   border-radius: 4px;
   padding: 5px;
   text-indent: 1em;
   outline: none;
   width: 60px;
   font-size: 9px;
}
</style>
